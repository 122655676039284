.input {
    &[type="text"],
    &[type="email"],
    &[type="url"],
    &[type="password"],
    &[type="search"],
    &[type="number"],
    &[type="tel"],
    &[type="range"],
    &[type="date"],
    &[type="month"],
    &[type="week"],
    &[type="time"],
    &[type="datetime"],
    &[type="datetime-local"],
    &[type="color"],
    textarea {
        border-radius: 8px;
        border: 1px solid rgba($color: $blue, $alpha: 80%);
        outline: none !important;
        color: $blue !important;
        padding: 0.8rem 1.6rem;
        margin-top: 2.4rem;
        width: 100%;
        transition: $trans;
        background-color: $white;

        &:focus {
            border-color: $blue;
        }
    }
}
