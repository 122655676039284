a:focus,
button:focus,
input:focus,
select:focus,
textarea:focus {
    box-shadow: none;
    -webkit-tap-highlight-color: transparent;
}

img {
    display: block;
    font-weight: 300;
    height: auto;
    line-height: 2;
    position: relative;
    text-align: center;
    width: 100%;

    &::before {
        content: "We're sorry, the image below is broken";
        display: block;
        margin-bottom: 1rem;
    }

    *::after {
        content: "(url: " attr(src) ")";
        display: block;
        font-size: 1.2rem;
    }
}

input,
select {
    border-radius: 0;
}

*::selection {
    background-color: $black;
    color: $white;
}

#webcamimg,
#qrimg {
    padding: 0 5px;
}

.lottie-el {
    pointer-events: none;
}

.welcome-lottie {
    width: 60rem;
    position: absolute;
    right: 0;
    bottom: 0;
    transform: translateX(30rem) translateY(0) rotate(-34.692deg);
}

.intro-lottie {
    width: 100%;
    background-image: url(../media/svgs/novo_bg.svg);
    background-size: contain;
    padding-top: 2rem;
    background-position: top;
    background-repeat: no-repeat;
    position: relative;
    margin-bottom: 25px;

    &-1,
    &-2 {
        position: absolute;
        bottom: 0;
        opacity: 0;
        width: 100%;
        height: 45vh;
        transition: 0.1s ease;
    }

    &-1 {
        position: relative;
        opacity: 1;
    }

    &.-change {
        & .intro-lottie-2 {
            opacity: 1;
        }

        & .intro-lottie-1 {
            opacity: 0;
        }
    }
}

.hide {
    opacity: 0;
    pointer-events: none;
}

.menu-footer-menu-container,
div.menu {
    margin-top: auto;
    padding-bottom: 40px;
}

ul.menu,
.menu ul {
    @include flex(center, center, 1rem, column);

    display: none;
    list-style: none;
    margin-top: 12rem;

    & a {
        color: #3c3c3c;
    }
}

.page-template-templateswelcome-page-php {
    ul.menu,
    .menu ul {
        display: flex !important;
    }
}

.languages + div.menu ul,
.languages + div .menu {
    display: flex !important;
}

.pb--6 {
    padding-bottom: 8rem !important;
}

.result-image {
    background-image: url(../media/svgs/novo_bg.svg);
    background-size: 45%;
    padding-top: 3rem;
    background-position: top;
    background-repeat: no-repeat;
    position: relative;
    width: 150%;

    &__reveal {
        width: 100%;
        height: 100%;
        position: fixed;
        top: 0;
        left: 0;
        z-index: 100;
        pointer-events: none;

        &::before,
        &::after {
            content: "";
            background-color: $blue;
            width: 51%;
            height: 100%;
            position: absolute;
            top: 0;
            animation: revealResult 1s 0.5s ease forwards;
        }

        &::before {
            left: 0;
            transform-origin: left;
        }

        &::after {
            right: 0;
            transform-origin: right;
        }
    }
}

@keyframes revealResult {
    0% {
        transform: scaleX(1);
    }

    100% {
        transform: scaleX(0);
    }
}

.max-w {
    @include max-w;
}

.padded, .entry-content {
    padding: 0 var(--spacer);
    width: 100%;
    max-width: 60rem;
}

.entry-content{
    @include flex(flex-start, flex-start, 2rem, column);

    width: 100%;
    position: relative;
    text-align: left;
    padding-bottom: 8rem;

    & p{
        color: $grey;
    }
}

.entry-content .wp-block-heading{
    margin-top: 3rem;
}