// defaults
$black: #1c1c1c;
$white: #fff;
$grey: #3c3c3c;
$blue: #1e22aa;
$light-blue: #f2f2ff;
$gold: #d1b29d;

.grey {
    color: $grey;
}
