@font-face {
    font-family: "Tokino Kun";
    src: url("../media/fonts/tokino_kun/Tokino Kun.ttf");
    font-weight: bold;
    font-display: swap;
}
@font-face {
    font-family: "Caveat";
    src: url("../media/fonts/caveat/caveat-bold.ttf");
    font-weight: bold;
    font-display: swap;
}

$heading-font: montserrat, arial, sans-serif;

html,
body {
    font-family: $heading-font;
    --clue: "Tokino Kun", arial, sans-serif;

    &:lang(es-ES),
    &:lang(de-DE),
    &:lang(fr-FR),
    &:lang(it-IT),
    &:lang(pt-PT),
    &:lang(nl-NL),
    &:lang(pl-PL) {
        --clue: "Caveat", arial, sans-serif;
    }
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: $heading-font;
    font-style: normal;
    font-weight: bold;
}

body,
p,
ul,
ol,
input,
button,
select,
label,
a,
.btn,
* {
    font-family: $heading-font;
    font-style: normal;
    font-weight: 400;
}
