.text-slider {
    width: 100%;

    --swiper-pagination-bullet-size: 12px;
    --swiper-pagination-bullet-inactive-color: #fff;
    --swiper-pagination-color: #1e22aa;
    --swiper-pagination-bullet-active-color: #1e22aa;
    --swiper-pagination-bullet-inactive-opacity: 1;
    --swiper-pagination-bottom: 1.4rem;

    border: 0.1rem solid $blue;
    border-radius: 0.8rem;
    padding: 4.8rem 2.4rem;
    text-align: left;
    position: relative;
    overflow: hidden;

    &__wrap {
        position: relative;
        margin-bottom: 20px;
        width: 100%;

        & .tag {
            position: absolute;
            top: 0;
            left: 2.4rem;
            transform: translateY(-50%);
            z-index: 2;
        }
    }

    & .swiper-wrapper {
        align-items: center;
    }

    & .swiper-slide {
        @include flex(flex-start, flex-start, 1rem, column);

        width: 100%;
    }

    & .swiper-pagination-bullet {
        border: 1px solid $blue;
    }
}
