.certificate {
    @include flex(space-between, flex-start, 4rem, column);

    height: 70rem;
    max-height: 70rem;
    position: relative;

    & > p {
        text-align: left;
        color: $blue;
    }

    & .text-slider {
        padding-bottom: 1rem;

        &__wrap {
            & > .tag {
                left: 50%;
                transform: translate(-50%, -50%);
                white-space: nowrap;
            }
        }

        & p {
            margin-bottom: 10px;
            color: $blue;
        }

        & strong {
            color: #3c3c3c;
        }

        & h3 {
            font-family: var(--clue);
            color: $blue;
            border-bottom: 1px solid #cacaca;
        }

        & img {
            width: 60%;
            margin-left: -5%;
            aspect-ratio: 1/1;
        }

        .inputs {
            width: 100%;
            display: flex;
            flex-direction: column;
            gap: 15px;

            &__input {
                display: flex;
                justify-content: flex-end;
                align-items: flex-end;
                gap: 11px;

                & p {
                    margin-bottom: 0;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 20px;
                }

                &__content {
                    border-bottom: 1px solid #cacaca;
                    min-width: 139px;
                    font-family: var(--clue);
                    color: $blue;
                    padding-left: 1px;

                    & svg {
                        margin-bottom: -8px;
                    }

                    @media (max-width: 425px) {
                        min-width: 100px;
                    }
                }
            }
        }
    }

    .welcome-lottie {
        top: 43rem;
        transform: translateX(30rem) rotate(-34.692deg);
    }

    .row {
        display: flex;
        align-items: center;
        width: 100%;
        justify-content: center;

        &--15 {
            gap: 15px;
        }
    }
}
