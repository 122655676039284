.seek {
    &-archive {
        @include flex(center, center, 2.4rem, column);

        margin-bottom: 2.4rem;
    }

    &-wrapper {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-template-rows: 1fr 1fr;
        grid-gap: 1.6rem;
        width: 100%;
        padding: 0 var(--spacer);
    }

    &-item {
        border-radius: 50%;
        width: 100%;
        height: auto;
        aspect-ratio: 1/1;
        overflow: hidden;
        background-color: transparent;
        position: relative;
        border: 1px solid transparent;
        cursor: pointer;
        transition: $trans;

        &:has(input:checked) {
            border-color: $blue;
        }

        & input {
            visibility: hidden;
            opacity: 0;
            width: 100%;
            height: 100%;
            position: absolute;
            left: 0;
            top: 0;
            z-index: 2;

            &:checked + label {
                border-color: $blue;
                scale: 0.9;
            }
        }

        & label {
            border-radius: 50%;
            width: 100%;
            height: 100%;
            aspect-ratio: 1/1;
            overflow: hidden;
            background-color: #f2f2ff;
            position: relative;
            display: block;
            transition: $trans;

            & img {
                width: 100%;
                height: 100%;
                object-fit: contain;
                pointer-events: none;
                object-position: bottom;
                opacity: 0;
                position: absolute;

                &.seek-image-grey {
                    opacity: 1;
                }
            }
        }

        &.seek-passed {
            & .seek-image-found {
                opacity: 1;
            }

            & img.seek-image-grey {
                opacity: 0 !important;
            }
        }
    }

    &-result-page {
        padding-bottom: 8rem;
    }

    &-camera-open {
        background-color: $blue;
        color: $white;
        margin-top: -6.1rem;
        padding-top: 6.1rem !important;
        padding-left: var(--spacer);
        padding-right: var(--spacer);
        padding-bottom: 10rem;
        height: 100%;
        min-height: 35vh;

        & + .menu-footer-menu-container {
            display: none;
        }

        .single-seek--title {
            @include flex(center, center, 0.8rem, column);
        }

        .button-wrapper form {
            @include flex(center, center, 0.8rem, column);

            width: 100%;

            & .input {
                margin: 0;
            }

            & .button {
                color: $white;
                margin-top: 2rem;
                text-decoration: none;
            }
        }

        #qr-reader {
            #mainbody {
                display: block !important;
                padding: 2rem 0;

                & a {
                    color: $white;
                }

                & #qr-canvas {
                    border-radius: 10px;
                    overflow: hidden;

                    // aspect-ratio: 16/16;
                    margin: 2rem 0;
                }
            }
        }
    }
}
