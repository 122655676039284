@mixin responsive-font($font-size) {
    $ideal: calc(($font-size) * 1px);

    font-size: $ideal;
}

@mixin link-colour($colour, $hover-colour: darken($colour, 20%)) {
    color: $colour;
    transition: $trans;

    @include hover {
        &:not(.link--no-hover) {
            color: $hover-colour;
        }
    }
}

@mixin max-w {
    padding: 0 var(--spacer);
    max-width: 60rem;
    width: 84.7vw;
    margin: 0 auto;
}

@mixin max-w-restrict {
    @include max-w;

    max-width: var(--max-w-restrict);
}

@mixin flex($justifyContent: flex-start, $alignItems: flex-start, $gap: 0, $dir: row) {
    display: flex;

    @if $justifyContent != "flex-start" {
        justify-content: $justifyContent;
    }

    @if $alignItems != "flex-start" {
        align-items: $alignItems;
    }

    @if $gap != 0 or $gap != 0 {
        gap: $gap;
    }

    @if $dir != "row" {
        flex-direction: $dir;
    }
}

@mixin even-grid($cols: 0, $rows: 0, $gap: 0) {
    display: grid;

    @if $cols != 0 {
        grid-template-columns: repeat($cols, 1fr);
    }

    @if $rows != 0 {
        grid-template-rows: repeat($cols, 1fr);
    }

    @if $gap {
        @if $gap != 0 or $gap != 0 {
            grid-gap: $gap;
        }
    }
}

@mixin auto-even-grid($gap: 0) {
    display: grid;
    grid-auto-columns: minmax(0, 1fr);
    grid-auto-flow: column;

    @if $gap != 0 {
        grid-gap: $gap;
    }
}

@mixin hover {
    @media (hover: hover) and (pointer: fine) {
        &:hover {
            @content;
        }
    }
}

@mixin abs-center {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

@mixin abs-background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: -1;
}
