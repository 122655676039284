.languages {
    @include flex(center, center, 2.4rem, column);

    min-height: 60vh;

    &__grid {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-template-rows: repeat(2, 1fr);
        grid-gap: 0.8rem;
        width: 100%;
    }

    &__flag {
        border-radius: 50%;
        width: 100%;
        height: auto;
        aspect-ratio: 1/1;
        overflow: hidden;
        border: 0.2rem solid #e0e0e0;

        &:has(.selected) {
            border-color: $blue;
        }

        & img {
            width: 100%;
            height: 100%;
            object-fit: contain;
            scale: 1.1;
        }
    }

    & .btn {
        margin-top: 2rem;
    }
}
