.wpml-ls-legacy-dropdown {
    width: 4rem;
    position: absolute;
    right: var(--spacer);

    & .wpml-ls-native {
        display: none;
    }

    & a {
        background-color: transparent !important;
        padding: 0 !important;
        border: none;

        &::after,
        & span {
            display: none !important;
        }
    }

    .wpml-ls-sub-menu {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 1.5rem;
        background-color: $blue;
        padding: 2rem var(--spacer);
        width: calc(11.5rem + var(--spacer));
        margin-left: var(--spacer);
        box-sizing: border-box;
        position: absolute;
        top: 5rem;
        right: 0;
        margin-right: calc(var(--spacer) * -1);
        left: unset;
        border-top: none;
    }
}
