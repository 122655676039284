$dsk-lg: 112.5em; // 1800px
$dsk: 106em; // 1696px
$lpt-lg: 82em; // 1312px
$lpt: 72em; // 1100px
$tbl: 56.25em; // 900px
$mbl-lg: 40em; // 640px
$mbl: 26em; // 416px

$trans: all 0.35s ease;
$border-radius-sm: 0.4rem;
$border-radius-md: 0.6rem;
$border-radius-lg: 0.8rem;
$shadow: 0 0 40px rgb(0 0 0 / 30%);
$bg-blur: blur(10px);

:root {
    --spacer: 6vw;
    --max-w: 84.7vw;
    --max-w-restrict: 75vw;

    @media screen and (min-width: 636px) {
        --spacer: 4rem;
    }
}
