.clue {
    &-card {
        color: $white;
        border-radius: 10px;
        background-color: $blue;
        padding: 2.5rem 1.2rem;
        position: relative;

        &__img {
            position: absolute;
            z-index: -1;
            width: 100%;
            height: 100%;
        }

        &__title {
            text-decoration: underline;
        }

        &__hint {
            padding: 7rem var(--spacer);

            & p,
            & h5 {
                @include responsive-font(36);

                font-family: var(--clue);
                font-style: normal;
                font-weight: 400;
                line-height: 100%;
            }
        }
    }
}

.single-seek-container,
.single-seek--content {
    @include flex(center, center, 3rem, column);

    width: 100%;
    position: relative;
}

.single-seek--content {
    animation: fadeIn 1s 1.4s ease forwards;
    opacity: 0;
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

.button-wrapper {
    @include flex(center, center, 7rem, column);

    padding-bottom: 2.4rem;
}

.percy-the-penguin .clue-card__img {
    width: 217px;
    height: 255px;
    margin-bottom: -27px;
    bottom: 0;
}

.debra-the-zebra .clue-card__img {
    width: 83.816px;
    height: 112.437px;
    transform: rotate(-20.855deg) translateY(-50%);
    left: 0;
    top: 35%;
    margin-left: -20px;
}

.tami-the-tiger .clue-card__img {
    width: 64.622px;
    height: 72.783px;
    transform: translateY(-50%);
    right: 0;
    top: 60%;
    margin-right: -30px;
}

.emilio-the-elephant .clue-card__img {
    width: 75px;
    height: 74px;
    transform: rotate(9.668deg) translateY(-50%);
    left: 0;
    top: 0%;
    margin-left: -40px;
}

.joey-the-koala .clue-card__img {
    width: 131.682px;
    height: 190.644px;
    transform: translateY(-50%);
    right: 0;
    top: 80%;
    margin-right: -32px;
}

.cici-the-sea-lion .clue-card__img {
    width: 216.125px;
    height: 177.441px;
    left: 0;
    top: 0;
    margin-left: -55px;
}
